import React, { Component } from 'react'
import './PlayerVideo.css'

//Dependências externas
import ModalVideo from 'react-modal-video'
import 'react-modal-video/scss/modal-video.scss'


export default class Player extends Component {

    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal() {
        this.setState({ isOpen: true })
    }

    render() {
        return (
            <div>
                <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='0qSd_ZpQ7ks' onClose={() => this.setState({ isOpen: false })} />
                <div className="player-video">
                    <i className="fas fa-play-circle" onClick={this.openModal}></i>
                </div>
            </div>
        )
    }
}
