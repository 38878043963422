import React, { Component } from 'react'

//Dependências Internas
import Starter01PNG from '../../../assets/images/telas/sistema-multas-plano-starter.png'
import Starter02PNG from '../../../assets/images/telas/sistema-reservas-plano-starter.png'
import Starter03PNG from '../../../assets/images/telas/sistema-vinculo-condutores-plano-starter.png'
import Starter04PNG from '../../../assets/images/telas/sistema-condutores-plano-starter.png'
import Starter05PNG from '../../../assets/images/telas/sistema-dashboard-plano-starter.png'
import Starter06PNG from '../../../assets/images/telas/sistema-manutencoes-plano-starter.png'
import Starter07PNG from '../../../assets/images/telas/sistema-minha-frota-plano-stater.png'
import Elite01PNG from '../../../assets/images/telas/sistema-monitor-plano-elite.png'
import Elite02PNG from '../../../assets/images/telas/sistema-historico-plano-elite.png'

//Dependências Externas
import ImageGallery from 'react-image-gallery'
import "react-image-gallery/styles/css/image-gallery.css";


const images = [
    {
        original: Starter01PNG,

    },
    {
        original: Starter02PNG,

    },
    {
        original: Starter03PNG,

    },
    {
        original: Starter04PNG,

    },
    {
        original: Starter05PNG,

    },
    {
        original: Starter06PNG,

    },
    {
        original: Starter07PNG,

    },
    {
        original: Elite01PNG,

    },
    {
        original: Elite02PNG,

    },
];

const properties = {
    showPlayButton: false,
    showThumbnails: false,
    sizes: "500px",
}


export default class Slider extends Component {
    render() {
        return <ImageGallery items={images} {...properties} />;
    }
}