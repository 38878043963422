import React from "react";
import "./Depoimentos.css";

//Dependências Internas
import Card from "./card-depoimento/CardDepoimento";
import FabioOTG from "../../assets/images/clientes/fabio-otg.jpg";
import MatheusVatten from "../../assets/images/clientes/matheus-vatten.jpg";

//Dependências Externas
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "animate.css";
import ReactWOW from "react-wow";

export default (props) => (
  <section className="depoimentos padding-section">
    <div className="container">
      <ReactWOW animation="fadeIn">
        <div className="text-center title-group">
          <h3>Depoimentos de Nossos Clientes</h3>
          <hr className="divisor" />
          <p>
            Conheça as vantagens operacionais e a economia que nosso sistema
            Carorama Fleet pode gerar para seus negócios. Abaixo alguns clientes
            satisfeitos:
          </p>
        </div>
      </ReactWOW>
      <ReactWOW animation="slideInUp">
        <div>
          <OwlCarousel
            className="owl-theme"
            items={1}
            loop={true}
            nav={false}
            dots={false}
            autoplay={true}
            autoplayHoverPause={true}
            autoplaySpeed={500}
            autoplayTimeout={2500}
          >
            <div className="item text-center">
              <Card
                nome="Matheus Arruda"
                cargo="Proprietário, VATTEN – SOLUÇÕES AMBIENTAIS"
                depoimento="Hoje com o sistema da Carrorama temos o controle total da nossa frota, antes desta solução nosso controle era limitado 
                        a planilha de Excel e não tínhamos processos bem definidos. Com a implementação do software, conseguimos organizar nossas atividades e
                        melhorar significativamente nossa operação. 
                        Otimizamos nosso tempo, melhoramos a qualidade das informações e temos relatórios que demonstram a eficiência da nossa área aqui na empresa."
                imagem={MatheusVatten}
              />
            </div>
            <div className="item text-center">
              <Card
                nome="Fábio Roso"
                cargo="Gerente de Logística, OTG – OPEN TECHNOLOGIES GROUP"
                depoimento="Com o Carrorama conseguimos controlar todos os nossos processos e solucionou nosso grande problema 
                        que eram as Multas. Através do sistema registramos todas as utilizações dos veículos tanto convencional quanto pool,
                        desta forma conseguimos identificar 100% dos condutores infratores, bem como controlar o fluxo de desconto em folha.
                        Os relatórios e indicadores comprovam nossa evolução dos resultados de forma simples e rápida."
                imagem={FabioOTG}
              />
            </div>
          </OwlCarousel>
        </div>
      </ReactWOW>
    </div>
  </section>
);
