import React from 'react'

const whats_number = '5515991589897'

export default function WhatsButton() {
  return (
    <>
      <link rel="stylesheet" href="https://cdn.positus.global/production/resources/robbu/whatsapp-button/whatsapp-button.css" />
      <a
        id="robbu-whatsapp-button"
        rel="noopener noreferrer"
        className="right"
        target="_blank"
        style={{ zIndex: 100 }}
        href={`https://api.whatsapp.com/send?phone=${whats_number}&text=Fale%20com%20um%20especialista.`}
      >
        <img src="https://cdn.positus.global/production/resources/robbu/whatsapp-button/whatsapp-icon.svg" alt="Botão whatsapp" />
      </a>
    </>
  )
}