import React from 'react'
import './LoginPerfil.css'

//Dependencias Externas
import '@fortawesome/free-solid-svg-icons'


export default props =>
    <section className="login">
        <div className="container">
            <div className="row">
                <div className="col-md-6 d-flex justify-content-end">
                    <div className="perfil-card">
                        <div className="icon">
                            <i class="fas fa-mobile-alt"></i>
                        </div>
                        <div className="card">
                            <h4>
                                <span className="text-yellow">CARRO<span className="text-nobold">RAMA</span></span> APP
                            </h4>
                            <hr />
                            <a href="#">
                                <button className="btn btn-primary">Entrar</button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 d-flex justify-content-start">
                    <div className="perfil-card">
                        <div className="icon">
                            <i class="fas fa-globe"></i>
                        </div>
                        <div className="card">
                            <h4>
                                <span className="text-yellow">CARRO<span className="text-nobold">RAMA</span></span> WEB
                            </h4>
                            <hr />
                            <a href="https://fleet.carrorama.net">
                                <button className="btn btn-primary">Entrar</button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 d-flex justify-content-center">
                    <div className="button-not-client">
                        <a href="/contato#contato" className="notclient-link">Ainda não é nosso cliente?</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
