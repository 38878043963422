import React from 'react'

//Dependências Internas
import carroramaStarterWEBP from '../../../assets/images/carrorama-starter.webp'
import carroramaStarterPNG from '../../../assets/images/carrorama-starter.png'
import carroramaStarterJP2 from '../../../assets/images/carrorama-starter.jp2'

export default props =>
    <div className="row">
        <div className="col-md-12 text-center">
            <div className="title-group mb-2">
                <h3>Carrorama Fleet<br />
                    <span>Plano Starter</span>
                </h3>
                <hr className="divisor-yellow-right" />
                <p>Software para auto-gestão da sua frota, com plataforma WEB e App para condutores.</p>
            </div>

            <picture>
                <source type="image/webp" srcSet={carroramaStarterWEBP} alt="Foto ilustrativa do Carrorama Fleet" />
                <source type="image/jp2" srcSet={carroramaStarterJP2} alt="Foto ilustrativa do Carrorama Fleet" />
                <img src={carroramaStarterPNG} alt="Foto ilustrativa do Carrorama Fleet" />
            </picture>

            <ul className="pl-4">
                <li className="lista-li"><span className="li-text">Redução de custos de até 30%</span></li>
                <li className="lista-li"><span className="li-text">Identifique desvios e oportunidades</span></li>
                <li className="lista-li"><span className="li-text">Gerencie em plataforma WEB</span></li>
                <li className="lista-li"><span className="li-text">Aplicativo para condutores</span></li>
                <li className="lista-li"><span className="li-text">Percurso e alertas em tempo real</span></li>
                <li className="lista-li"><span className="li-text">Disponível para carros, ônibus e caminhões</span></li>
                <li className="lista-li"><span className="li-text">Teste Gratuitamente antes de adquirir!</span></li>
            </ul>
        </div>
    </div>