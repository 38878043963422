import React from 'react'

//Dependências Internas
import carroramaEliteWEBP from '../../../assets/images/carrorama-elite.webp'
import carroramaElitePNG from '../../../assets/images/carrorama-elite.png'
import carroramaEliteJP2 from '../../../assets/images/carrorama-elite.jp2'

export default props =>
    <div className="row">
        <div className="col-md-12 text-center">
            <div className="title-group mb-2">
                <h3>Carrorama Fleet<br />
                    <span>Plano Elite</span>
                </h3>
                <hr className="divisor-yellow-right" />
                <p>Software para auto-gestão da sua frota, integrado a telemetria através de dispositivo OBD m2m.</p>
            </div>

            <picture>
                <source type="image/webp" srcSet={carroramaEliteWEBP} alt="Foto ilustrativa do Carrorama Elite" />
                <source type="image/jp2" srcSet={carroramaEliteJP2} alt="Foto ilustrativa do Carrorama Elite" />
                <img src={carroramaElitePNG} alt="Foto ilustrativa do Carrorama Elite" />
            </picture>

            <ul className="pl-4">
                <li className="lista-li"><span className="li-text">Redução de custos de até 30%</span></li>
                <li className="lista-li"><span className="li-text">Identifique desvios e oportunidades</span></li>
                <li className="lista-li"><span className="li-text">Gerencie em plataforma WEB</span></li>
                <li className="lista-li"><span className="li-text">Aplicativo para condutores</span></li>
                <li className="lista-li"><span className="li-text">Histórico de percurso</span></li>
                <li className="lista-li"><span className="li-text">Disponível para carros, ônibus e caminhões</span></li>
                <li className="lista-li"><span className="li-text">Conectividade Plugplay, você conecta e utiliza na hora!</span></li>
                <li className="lista-li"><span className="li-text">Teste Gratuitamente antes de adquirir!</span></li>
                <li className="lista-li"><span className="li-text">Relatório de localização, consumo de combustível, tempo de condução do veículo</span></li>
                <li className="lista-li"><span className="li-text">Alertas em tempo real como: excesso de velocidade, frenagem brusca, aceleração brusca, colisão, alta temperatura do motor, entre outros</span></li>
                <li className="lista-li"><span className="li-text">Analise de trajetos </span></li>
                <li className="lista-li"><span className="li-text">Cerca eletrônica</span></li>
            </ul>
        </div>
    </div>