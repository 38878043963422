import React, { Component } from "react";
import "./Header.css";

//Dependências Internas
import logoWEBP from "../assets/images/logo-carrorama-fleet.webp";
import logoPNG from "../assets/images/logo-carrorama-fleet.png";
import logoJP2 from "../assets/images/logo-carrorama-fleet.jp2";

//Dependências Externas
import $ from "jquery";

export default class Header extends Component {
  handleChange(event) {
    let id = event.target.id;
    $(".navbar-nav").find(".active").removeClass("active");
    $(`#${id}`).addClass("active");
  }

  render() {
    return (
      <div className="header">
        <nav className="navbar fixed-top navbar-expand-lg navbar-dark">
          <a className="navbar-brand" href="/home">
            <picture>
              <source
                type="image/webp"
                srcSet={logoWEBP}
                alt="Logo CarroramaFleet"
              />
              <source
                type="image/jp2"
                srcSet={logoJP2}
                alt="Logo CarroramaFleet"
              />
              <img src={logoPNG} alt="Logo CarroramaFleet" />
            </picture>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Alterna navegação"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse text-right"
            id="navbarNavAltMarkup"
          >
            <div className="navbar-nav ml-auto">
              <a
                className="nav-item nav-link"
                id="home-nav"
                href="/home"
                onClick={this.handleChange}
              >
                Home <span className="sr-only">(Página atual)</span>
              </a>
              <a
                className="nav-item nav-link"
                id="sobre-nav"
                href="/sobre-nos"
                onClick={this.handleChange}
              >
                Sobre
              </a>
              <a
                className="nav-item nav-link"
                id="planos-nav"
                href="/planos"
                onClick={this.handleChange}
              >
                Planos
              </a>
              <a
                className="nav-item nav-link"
                id="faq-nav"
                href="/faq"
                onClick={this.handleChange}
              >
                Faq
              </a>
              <a
                className="nav-item nav-link"
                id="Blog-nav"
                href="/blog"
                onClick={this.handleChange}
              >
                Blog
              </a>
              <a
                className="nav-item nav-link"
                id="contato-nav"
                href="#contato"
                onClick={this.handleChange}
              >
                Contato
              </a>
              <a href="https://fleet.carrorama.net/">
                <button className="btn btn-primary text-nowrap painel-button">
                  <i class="fas fa-user"></i> Painel do Cliente
                </button>
              </a>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}
