import React from 'react'
import './Appcondutor.css'

//Dependências Internas 
import AppCondutorWEBP from '../../assets/images/app-condutor-carrorama-1.webp'
import AppCondutorPNG from '../../assets/images/app-condutor-carrorama-1.png'
import AppCondutorJP2 from '../../assets/images/app-condutor-carrorama-1.jp2'

import IconeAppWEBP from '../../assets/images/app-condutor-icone.webp'
import IconeAppPNG from '../../assets/images/app-condutor-icone.png'
import IconeAppJP2 from '../../assets/images/app-condutor-icone.jp2'

import IconeAppAndroidWEBP from '../../assets/images/app-condutor-icone-android.webp'
import IconeAppAndroidPNG from '../../assets/images/app-condutor-icone-android.png'
import IconeAppAndroidJP2 from '../../assets/images/app-condutor-icone-android.jp2'

import IconeAppIosWEBP from '../../assets/images/app-condutor-icone-ios.webp'
import IconeAppIosPNG from '../../assets/images/app-condutor-icone-ios.png'
import IconeAppIosJP2 from '../../assets/images/app-condutor-icone-ios.jp2'

// Dependências Externas
import 'animate.css'
import ReactWOW from 'react-wow'

export default props =>
    <section className="app-condutor padding-section">
        <div className="container">
            <ReactWOW animation='fadeIn'>
                <div className="text-center title-group mb-5">
                    <h3>App do Condutor</h3>
                    <hr className="divisor" />
                </div>
            </ReactWOW>
            <div className="row">
                <div className="col-12 col-md-6 d-flex justify-content-center mb-5">
                    <ReactWOW animation='slideInUp'>
                        <picture>
                            <source type="image/webp" srcSet={AppCondutorWEBP} alt="Imagem do aplicativo do Condutor" />
                            <source type="image/jp2" srcSet={AppCondutorJP2} alt="Imagem do aplicativo do Condutor" />
                            <img src={AppCondutorPNG} alt="Imagem do aplicativo do Condutor" />
                        </picture>

                    </ReactWOW>
                </div>
                <div className="col-12 col-md-6 d-flex align-items-center">
                    <ReactWOW animation='slideInUp'>
                        <div className="row">
                            <div className="col-12 col-md-6 d-flex justify-content-center">
                                <picture>
                                    <source type="image/webp" srcSet={IconeAppWEBP} alt="Imagem do icone do Aplicativo Carrorama" />
                                    <source type="image/jp2" srcSet={IconeAppJP2} alt="Imagem do icone do Aplicativo Carrorama" />
                                    <img src={IconeAppPNG} alt="Imagem do icone do Aplicativo Carrorama" />
                                </picture>
                            </div>
                            <div className="col-12 col-md-6 ">
                                <p className="desc-app text-center">
                                    Carrorama Fleet Starter Disponível para Android® e iOS®
                            </p>
                            </div>
                            <div className="col-6 col-md-6 mt-5  d-flex justify-content-center">
                                <div className="icone android">
                                    <a href="https://play.google.com/store/apps/details?id=br.com.going2.carroramafleet" rel="noopener noreferrer" target="_blank">
                                        <picture>
                                            <source type="image/webp" srcSet={IconeAppAndroidWEBP} alt="Imagem do icone do Android" />
                                            <source type="image/jp2" srcSet={IconeAppAndroidJP2} alt="Imagem do icone do Android" />
                                            <img src={IconeAppAndroidPNG} alt="Imagem do icone do Android" />
                                        </picture>
                                    </a>
                                </div>
                            </div>
                            <div className="col-6 col-md-6 mt-5  d-flex justify-content-center">
                                <div className="icone ios">
                                    <a href="https://itunes.apple.com/br/app/fleet-starter/id1133713623" rel="noopener noreferrer" target="_blank">
                                        <picture>
                                            <source type="image/webp" srcSet={IconeAppIosWEBP} alt="Imagem do icone do IOS" />
                                            <source type="image/jp2" srcSet={IconeAppIosJP2} alt="Imagem do icone do IOS" />
                                            <img src={IconeAppIosPNG} alt="Imagem do icone do IOS" />
                                        </picture>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </ReactWOW>
                </div>
            </div>
        </div>
    </section>