import React from 'react'
import './Telas.css'

//Dependências Internas
import Slider from './slider/Slider'

// Dependências Externas
import 'animate.css'
import ReactWOW from 'react-wow'

export default props =>
    <section className="telas padding-section">
        <div className="container">
            <ReactWOW animation='fadeIn'>
                <div className="text-center title-group mb-5">
                    <h3>Algumas Telas</h3>
                    <hr className="divisor-white" />
                    <p>Estas são algumas telas do Sistema Carrorama Fleet.</p>
                </div>
                <Slider />
            </ReactWOW>
        </div>

    </section>