import React from 'react'
import './Home.css'

//Dependências Internas
import Header from '../header/Header'
import Banner from './banner/Banner'
import Produtos from './produtos/Produtos'
import Telas from './telas/Telas'
import AppCondutor from './appcondutor/Appcondutor'
import Recursos from './recursos/Recursos'
import Totalizador from './totalizador/Totalizador'
import Depoimentos from './depoimentos/Depoimentos'
import Clientes from './clientes/Clientes'
import Contato from '../contato/Contato'
import Footer from '../footer/Footer'

export default props =>
    <section className="home">
        <Header />
        <Banner />
        <Produtos />
        <Telas />
        <AppCondutor />
        <Recursos />
        <Totalizador />
        <Depoimentos />
        <Clientes />
        {/* <Planos /> */}
        {/* <Faq /> */}
        <Contato />
        <Footer />
    </section>